import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    faqList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    sortColumn: "question",
    sortDirection: "asc",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.faqList.length > 0) {
        return (
          _.where(this.faqList, { isSelected: true }).length ===
          this.faqList.length
        );
      }
      return false;
    },
    hasFAQShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQImportPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Import FAQ" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Import FAQ" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Import FAQ" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQExportPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "export FAQ" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "export FAQ" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "export FAQ" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasFAQSHideShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "FAQ Show|Hide" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "FAQ Show|Hide" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "FAQ Show|Hide" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    checkUncheckedList(event) {
      this.faqList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.faqList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/faq-deletes", { ids: ids })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    updateFaqStatus(faqs, event) {
      let _vm = this;
      let _msg =
        faqs.isDisplay == true
          ? "Are you sure you want to Deactive this FAQ?"
          : "Are you sure you want to Active this FAQ?";
      let _isDisplay = faqs.isDisplay == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post(
              "/faq-update-status/id?id=" + faqs.id + "&isDisplay=" + _isDisplay
            )
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("faq-checkbox-" + faqs.id).checked =
                faqs.isDisplay;
            });
        })
        .catch(function () {
          document.getElementById("faq-checkbox-" + faqs.id).checked =
            faqs.isDisplay;
        });
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/faq" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.faqList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {});
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.selectedFile = [];
      Array.prototype.forEach.call(files, (item) => {
        this.selectedFile.push(item);
      });
    },
    uploadFaq() {
      if (this.selectedFile && this.selectedFile.length > 0) {
        let _vm = this;
        let fd = new FormData();
        fd.append("xlsx", this.selectedFile[0]);
        this.axios.post("/import/faq", fd).then((response) => {
          _vm.selectedFile = [];
          document.getElementById("file-input").value = null;
          _vm.getAll();
        });
      } else {
        this.$toast.error("Please select file first!");
      }
    },
    exportFaq() {
      this.axios.get("/export/faq").then((response) => {
        let a = document.createElement("a");
        a.href = response.data.data.file;
        a.download = response.data.data.name;
        a.click();
      });
    },

    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.faqList.map((item) => "checkbox" + item.id);
          this.faqList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.faqList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
